export default class EditCategoryShowResponse {
  constructor(response) {
    this.response = response;
  }

  /**
   * @return {EditCategoryShowCategory}
   */
  getCategory() {
    return new EditCategoryShowCategory(this.response.category);
  }

  /**
   * @return {EditCategoryShowParentCategory[]}
   */
  getParentCategories() {
    return this.response.parent_categories.map(
      (item) => new EditCategoryShowParentCategory(item)
    );
  }
}

class EditCategoryShowCategory {
  constructor(item) {
    this.item = item;
  }

  /**
   * @return {string}
   */
  getId() {
    return this.item.id;
  }

  /**
   * @return {string}
   */
  getName() {
    return this.item.name;
  }

  /**
   * @return {string}
   */
  getParentId() {
    return this.item.parent_id;
  }

  /**
   * @return {boolean}
   */
  isActive() {
    return this.item.is_active;
  }

  /**
   * @return {number}
   */
  getRowNumber() {
    return this.item.row_number;
  }

  /**
   * @return {string}
   */
  getShopifyCollectionId() {
    return this.item.shopify_collection_id;
  }

  /**
   * @return {string}
   */
  getImageName() {
    return this.item.image.name;
  }

  /**
   * @return {string}
   */
  getImageUrl() {
    return this.item.image.url;
  }
}

class EditCategoryShowParentCategory {
  constructor(item) {
    this.item = item;
  }

  /**
   * @return {string}
   */
  getId() {
    return this.item.id;
  }

  /**
   * @return {string}
   */
  getName() {
    return this.item.name;
  }

  /**
   * @return {string}
   */
  getParentId() {
    return this.item.parent_id;
  }
}
