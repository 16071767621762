<template>
  <ul v-if="items">
    <li v-for="(item, index) in items" :key="index" @click="doActive(item)">
      <router-link :to="item.to">
        <i :class="item.icon"></i>
        {{ item.label }}

        <span class="arrow" v-if="item.children">
          <i class="pi pi-chevron-down"></i>
        </span>
      </router-link>
      <ul
        :class="{active : item.isActive ||
        (childIsActive(item, index).true === true && childIsActive(item, index).index === index)}"
        class="child">
        <li v-for="(child, index) in item.children" :key="index">
          <router-link :to="child.to">
            <i :class="child.icon"></i>

            {{ child.label }}

          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import { reactive } from 'vue'
import useGlobal from '../src/composables/useGlobal'

export default {
  name: 'appsubmenu',
  props: {
    items: Array,
  },

  setup() {
    const { route } = useGlobal()

    const doActive = (item) => {
      item.isActive = ! item.isActive
    }

    const childIsActive = (item, index) => {

      let isTrue = reactive({
        true: false,
        index: 0,
      })

      if (item.children) {
        item.children.forEach(child => {
          if (child.to.name === route.name) {
            isTrue.true = true
            isTrue.index = index
          }
        })
      }
      return isTrue
    }

    return {
      route,
      doActive,
      childIsActive,
    }
  },
}
</script>

<style scoped>
li a {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: .4em;
}

.arrow {
  margin-left: auto;
  transition: all .2s;
}

.arrow i {
  font-size: .8em;
  color: gray;
}

.child {
  padding-left: 1em;
}


li .child {
  display: none;
}

.active {
  display: block !important;
}

li:hover .arrow {
  transform: rotate(90deg);
  transition: all .2s;
}

.router-link-exact-active {
  font-weight: 700;
}

</style>
