import { onMounted, ref, nextTick, computed, watch } from 'vue'
import useGlobal from '../useGlobal'
import isEmpty from 'is-empty'

export default function useGetSimulationData() {

  const { route, router, emitter, restApi } = useGlobal()

  const isLoading = ref(false)
  const hasError = ref(false)

  const items = ref([])

  emitter.on('refresh-simulation', () => {
    reloadSimulationData()
  })

  emitter.on('showcase-item-added', (id) => {

    reloadSimulationData().then(() => {

      router.push({ name: 'HomePage.Edit.Page', params: { id: id } }).then(() => {

        console.log('HomePage.Edit.Page pushed')

      })

    })

  })

  emitter.on('showcase-item-updated-sync', (newItem) => {

    console.log('showcaseItemUpdatedSync runned.')

    const newItems = []

    items.value.forEach(oldItem => {

      if (oldItem.id === newItem.id) {
        newItems.push(newItem)
      } else {
        newItems.push(oldItem)
      }

    })

    items.value = newItems

  })

  watch(route, () => {
    focus()
  })

  const displayableItems = computed(() => {

    console.log('displayableItems runned.')

    return items.value

  })

  onMounted(() => {

    isLoading.value = true

    restApi.fetchSimulation().then(response => {

      response.items.forEach(item => {
        items.value.push(item)
      })

      isLoading.value = false

      nextTick().then(() => {

        focus()

      })

    }).catch(() => {

      hasError.value = true
      isLoading.value = false

    })

  })

  const focus = () => {

    console.log('focus runned')

    if (
      ! isEmpty(route.params.id) &&
      ! isEmpty(document.getElementById('a' + route.params.id))
    ) {
      console.log('FOCUSA GİRDİ')
      document.getElementById('a' + route.params.id).scrollIntoView({
        behavior: 'smooth',
      })
    } else {
      console.log('FOCUS ELSE GİRDİ')
      document.getElementById('phoneHeader').scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const reloadSimulationData = () => {

    console.log('reloadSimulationData runned')

    return restApi.fetchSimulation().then(response => {

      items.value = []

      response.items.forEach(item => {
        items.value.push(item)
      })

      nextTick().then(() => {

        focus()

      })

    }).catch(() => {

      hasError.value = true

    })

  }

  return {

    isLoading,
    hasError,

    displayableItems,

    reloadSimulationData,

  }

}
