<template>
  <div class="Horizontal">
    <div class="horizontal">
      <div class="widgetHeader" v-if="showcaseItem.header.visible">
        <div class="title">
          <span>
            {{ showcaseItem.header.title }}
          </span>
        </div>
        <div class="subtitle">
          <span
            v-if="showcaseItem.header.right.visible"
            :style="{ color: color.primary_color }"
          >
            {{ showcaseItem.header.right.title }}
          </span>
        </div>
      </div>
      <div
        class="item"
        :style="{
          gridTemplateColumns:
            'repeat(' + showcaseItem.body.items?.length + ', 1fr)',
        }"
      >
        <div v-for="(item, index) in showcaseItem.body.items" :key="index">
          <div>
            <div class="image">
              <div class="bg" :style="{aspectRatio: showcaseItem.body.ratio}">
                <img v-if="item.image_url" :src="item.image_url"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showcaseItem: {
      type: Object,
      required: true
    },
    color: Object
  }
}
</script>

<style scoped>
.Horizontal {
  overflow-x: scroll;
}

.horizontal .item {
  display: grid;
  gap: 0.5em;
  width: max-content;
  transition: all 1s;
  position: relative;
  border-radius: 1em;
}

.horizontal .item .title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: 600;
  color: #fff;
  font-size: 2em;
  text-align: center;
}

.bg {
  width: 300px;
}

.bg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
