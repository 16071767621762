export default class AddShowcaseResponse {

  constructor(data) {
    this.data = data
  }

  /**
   * @returns {string}
   */
  getId() {
    return this.data.showcase_item_id
  }

}
