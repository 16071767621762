import {onMounted, ref} from 'vue';
import useGlobal from "./useGlobal";

export default function useGetCategories() {

  const {emitter, restApi} = useGlobal()

  const hasError = ref(false)
  const errorMessage = ref('')
  const isLoading = ref(false)

  const categories = ref(new Map())

  const getCategories = () => {

    isLoading.value = true

    restApi.fetchCategories().then(response => {
      categories.value = response.getItems()
      isLoading.value = false
    }).catch(error => {
      hasError.value = true
      errorMessage.value = error.toString()
      isLoading.value = false
    })

  }

  onMounted(getCategories)

  onMounted(() => {

    emitter.on('refresh-categories', () => {

      getCategories()

    })

  })

  return {
    hasError,
    errorMessage,
    isLoading,
    categories
  }

}
