export default {
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış Yap"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vazgeç"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydediliyor"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama yap"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aç"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüle"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tümü"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktif"])},
  "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasif"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekle"])},
  "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekleniyor"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaldır"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamam"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
  "parentCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üst Kategori"])},
  "subCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Kategorinin Adı"])},
  "enterCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori adı girin"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
  "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasarım"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama Yayınlama"])},
  "helpSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım & Destek"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yardım"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
  "categoryImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori görseli"])},
  "categoryCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori koleksiyonları"])},
  "isCategoryStatusActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori durumu aktif olsun mu?"])},
  "rowNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıra numarası"])},
  "showcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin"])},
  "create": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluştur"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori oluştur"])},
    "showcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin oluştur"])},
    "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koleksiyon oluştur"])},
    "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim oluştur"])}
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seç"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konum"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tür"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarih"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boyut"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçerik"])},
  "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleme hatası"])},
  "maxFileSizeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya boyutu çok büyük"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilgi"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genişlik"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükseklik"])},
  "ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oran"])},
  "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya seç"])},
  "selectFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasör seç"])},
  "selectImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resim seç"])},
  "maxFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum dosya boyutu"])},
  "categoryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler"])},
  "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori adı"])},
  "homePageManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrinler"])},
  "allShowcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm vitrinler"])},
  "loading": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler yükleniyor"])},
    "showcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrinler yükleniyor"])},
    "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koleksiyonlar yükleniyor"])},
    "simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simülasyon yükleniyor"])},
    "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal yükleniyor"])},
    "layout1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout1 yükleniyor"])},
    "layout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout2 yükleniyor"])},
    "layout3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout3 yükleniyor"])},
    "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertical yükleniyor"])},
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal medya yükleniyor"])}
  },
  "primaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana renk"])},
  "secondaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkincil renk"])},
  "someWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir şeyler yanlış gitti"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "searchResultsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama sonucu bulunamadı"])},
  "doActiveCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori aktif et"])},
  "doPassiveCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori pasif et"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylemler"])},
  "createSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt kategori oluştur"])},
  "editCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori düzenle"])},
  "showcaseImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin görseli"])},
  "IncreaseYourSalesWithMobileShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobil Alışveriş ile satışlarınızı artırın!"])},
  "NextGenerationMobileAppPlatformExpandsYourReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni nesil mobil uygulama platformu, erişiminizi genişletir ve mobil dünyada başarılı olmanıza yardımcı olur."])},
  "NoCodingRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kodlama gerektirmez, kullanışlı bir ayar ekranıyla müşterilerinizin seveceği bir tasarım elde edebilirsiniz."])},
  "welcomeFigmobi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figmobi'ye hoş geldiniz"])},
  "SignInToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devam etmek için giriş yapın"])},
  "yourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresiniz"])},
  "yourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanız"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yap"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
  "rightTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağ başlık"])},
  "headerIsVisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık görünür olsun mu?"])},
  "rightHeaderIsVisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağ başlık görünür olsun mu?"])},
  "notificationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler"])},
  "allNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm bildirimler"])},
  "memberName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye adı"])},
  "memberSurName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye soyadı"])},
  "memberEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye e-posta"])},
  "notificationContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim İçeriği"])},
  "targetUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hedef URL"])},
  "imgUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görsel URL"])},
  "dynamicSelectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamik Seçiciler"])},
  "storeManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza Ayarları"])},
  "showcaseCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin koleksiyonları"])},
  "showingItemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntülenecek eleman sayısı"])},
  "sort": {
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sırala"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varsayılan"])},
    "backToOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeniden eskiye"])},
    "increasedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artan fiyat"])},
    "decreasingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azalan fiyat"])},
    "alphabeticalAZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetik A-Z"])},
    "alphabeticalZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alfabetik Z-A"])}
  },
  "contentSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İçerik kaynağı"])},
  "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koleksiyon"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürün"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünler"])},
  "pageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa sayısı"])},
  "elementCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleman sayısı"])},
  "columnCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sütun sayısı"])},
  "newTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni başlık"])},
  "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon"])},
  "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İkon Url"])},
  "AreYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmek istediğinize emin misiniz?"])},
  "AreYouSureDeleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili öğeyi silmek istediğinize emin misiniz?"])},
  "basicSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temel ayarlar"])},
  "chooseShowcaseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin Türü Seçin"])},
  "colorSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renk ayarları"])},
  "selectCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir koleksiyon seçin"])},
  "selectMinCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen en az bir koleksiyon seçin"])},
  "required": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen başlık girin"])},
    "rightTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen sağ başlık girin"])},
    "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen kategori adı girin"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen bir görsel seçin"])}
  },
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza Adı"])},
  "storeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza Kimlik Numarası"])},
  "storeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza durumu"])},
  "currentLanguages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut diller"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Şartları"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gizlilik Politikası"])},
  "storeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza logosu"])},
  "storeAppLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza uygulama linki"])},
  "storeTermsLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza kullanım koşulları linki"])},
  "socialMedias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sosyal Medyalar"])},
  "notificationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirim Listesi"])},
  "createNewNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni bildirim oluştur"])},
  "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen tekrar deneyin"])},
  "noResultsFoundMatching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşleşen sonuç bulunamadı"])},
  "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç bulunamadı"])},
  "headerRightShowcaseCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sağ başlık koleksiyonu"])},
  "showcasesNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrinler getirilemedi"])},
  "categoriesNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoriler getirilemedi"])},
  "detailNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detay getirilemedi"])},
  "itemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleman Sayısı"])},
  "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni öğe ekle"])},
  "choiceThemeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema rengi seçin"])},
  "perPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfa başına ürün sayısı"])},
  "sourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynak türü"])},
  "personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kişiselleştirme"])},
  "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
  "enterYourAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama ismini girin"])},
  "storeLangs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mağaza Dilleri"])},
  "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yazı rengi"])},
  "onLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yayında"])},
  "categoryCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori oluşturuldu"])},
  "categoryAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori başarıyla oluşturuldu"])},
  "categoryNotCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori oluşturulamadı"])},
  "categoryCreatingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori oluştururken hata oluştu"])},
  "changesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedildi"])},
  "changesSavedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler başarıyla kaydedildi"])},
  "changesNotSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedilemedi"])},
  "changesNotSavedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Değişiklikler kaydedilirken hata oluştu"])},
  "showcaseCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin oluşturuldu"])},
  "showcaseCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin başarıyla oluşturuldu"])},
  "showcaseNotCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin oluşturulamadı"])},
  "showcaseNotCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin oluştururken hata oluştu"])},
  "showcaseItemDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi silindi"])},
  "showcaseItemDeletedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi başarıyla silindi"])},
  "showcaseItemNotDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi silinemedi"])},
  "showcaseItemNotDeletedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi silinirken hata oluştu"])},
  "productsNotFetched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünler getirilemedi"])},
  "productsNotFetchedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ürünler getirilirken hata oluştu"])},
  "maxItemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum ürün sınırına ulaşıldı"])},
  "maxItemCountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleman sayısı kadar ürün seçebilirsiniz."])},
  "showcaseItemUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi güncellendi"])},
  "showcaseItemUpdatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi başarıyla güncellendi"])},
  "showcaseItemNotUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi güncellenemedi"])},
  "showcaseItemNotUpdatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi güncellenirken hata oluştu"])},
  "showcaseItemCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi oluşturuldu"])},
  "showcaseItemCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi başarıyla oluşturuldu"])},
  "showcaseItemNotCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi oluşturulamadı"])},
  "showcaseItemNotCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitrin ögesi oluşturulurken hata oluştu"])}
}