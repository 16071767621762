<template>
  <div class="set">
    <a @click="$emit('edit')" v-if="showEditLink" class="btn">
      <i class="pi pi-pencil"></i>
    </a>

    <a @click="$emit('toggleVisible')" class="btn">
      <i class="pi pi-flag"></i>
    </a>

    <a @click="$emit('moveDown')" v-if="showDownButton" class="btn">
      <i class="pi pi-angle-double-down"></i>
    </a>

    <a @click="$emit('moveUp')" v-if="showUpButton" class="btn">
      <i class="pi pi-angle-double-up"></i>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ButtonSet',
  props: {
    showEditLink: Boolean,
    showDownButton: Boolean,
    showUpButton: Boolean,
  },

  setup() {},
};
</script>

<style scoped>
.set {
  right: 0;
  top: -2px;
  z-index: 9999999;
  background-color: #fff;
  border: 2px solid gray;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  gap: 0.4em;
}

.showcase:hover .set {
  display: flex;
}

.set:before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: -1;
  top: 102%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid gray;
}

.set a {
  background-color: #fff;
  font-size: 0.6em;
}

.btn {
  opacity: 0.6;
}

.btn:hover {
  opacity: 1;
}
</style>
