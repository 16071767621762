<template>
  <div class="vertical">
    <div class="Vertical">
      <div>
        <div class="widgetHeader" v-if="showcaseItem.header.visible">
          <div class="title">
            <span>
              {{ showcaseItem.header.title }}
            </span>
          </div>
          <div class="subtitle">
            <span
              v-if="showcaseItem.header.right.visible"
              :style="{ color: color.primary_color }"
            >
              {{ showcaseItem.header.right.title }}
            </span>
          </div>
        </div>
        <div
          class="grid"
          :style="{
            gridTemplateColumns:
              'repeat(' + showcaseItem.body.column_count + ', 1fr)',

          }"
        >
          <div
            class="image mb-3"
            v-for="(item, index) in showcaseItem.body.items"
            :key="index"
          >
            <div class="bg" :style="{aspectRatio: showcaseItem.body.ratio}">
              <img :src="item.image_url"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showcaseItem: {
      type: Object,
      required: true
    },
    color: Object
  }
}
</script>

<style scoped>
.Vertical {
  margin-top: 2em;
}

.grid {
  display: grid;
  margin: auto;
  gap: 0.5em;
}

.bg {
  width: 100%;
}

.vertical-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

</style>
