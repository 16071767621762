export default class GetCollectionsResponse {

  constructor(data) {
    this.data = data
  }

  getItems() {
    return this.data.items
  }

}
