<template>
  <div class="filter">
    <div class="row">
      <div class="col-lg-6">
        <div class="input-wrap search">
          <label for="search">{{ t('search') }}</label>
          <input :value="name" class="form-control" id="search"
                 type="search" :placeholder="t('search')"
                 @input="$emit('update:name', $event.target.value)"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="input-wrap search">
          <label>{{ t('status') }}</label>
          <select class="form-control" :value="status" id="status"
                  @change="$emit('update:status', $event.target.value)">
            <option value="all">{{ t('all') }}</option>
            <option value="1">{{ t('active') }}</option>
            <option value="0">{{ t('passive') }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useI18n } from 'vue-i18n'

export default {

  props: {
    name: String,
    status: String
  },

  setup() {

    const { t } = useI18n()

    return {
      t
    }

  }

}

</script>

<style scoped>
.filter {
  margin-bottom: 1em;
  padding: 0 1.5em;
}
</style>
