import { createRouter, createWebHistory } from "vue-router";
import Category from "./pages/CategoryManagement";
import HomePage from "./pages/HomePageManagement";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/category-management",
      name: "Index.Page",
      component: Category,
      children: [
        {
          path: "add",
          name: "Category.Add.Page",
          component: () => import("./components/CategoryManagement/AddCategory"),
        },
        {
          path: "add-sub/:id",
          name: "Category.AddSub.Page",
          component: () => import("./components/CategoryManagement/AddSubCategory"),
        },
        {
          path: "edit/:id",
          name: "Category.Edit.Page",
          component: () => import("./components/CategoryManagement/EditCategory"),
        },
      ],
    },

    {
      path: "/home-page-management",
      name: "HomePageManagement.Page",
      component: HomePage,
      children: [
        {
          path: "all",
          name: "HomePage.Allshowcases.Page",
          component: () => import("./components/HomePageManagement/AllShowCases"),
        },
        {
          path: "add",
          name: "HomePage.Add.Page",
          component: () => import("./components/HomePageManagement/CreateShowCase"),
          children: [
            {
              path: "horizontal",
              name: "Create.Horizontal",
              component: () => import("./components/HomePageManagement/AddShowCases/Horizontal"),
            },
            {
              path: "layout1",
              name: "Create.Layout1",
              component: () => import("./components/HomePageManagement/AddShowCases/Layout1"),
            },
            {
              path: "layout2",
              name: "Create.Layout2",
              component: () => import("./components/HomePageManagement/AddShowCases/Layout2"),
            },
            {
              path: "layout3",
              name: "Create.Layout3",
              component: () => import("./components/HomePageManagement/AddShowCases/Layout3"),
            },
            {
              path: "vertical",
              name: "Create.Vertical",
              component: () => import("./components/HomePageManagement/AddShowCases/Vertical"),
            },
            {
              path: "socialmedia",
              name: "Create.SocialMedia",
              component: () => import("./components/HomePageManagement/AddShowCases/SocialMedia"),
            },
          ],
        },
        {
          path: "edit/:id",
          name: "HomePage.Edit.Page",
          component: () => import("./components/HomePageManagement/EditShowCase"),
        },
      ],
    },
    {
      path: "/store-management",
      name: "StoreManagement.Page",
      component: () => import("./pages/StoreManagement"),
      redirect: { name: "StoreManagement.BasicSettings.Page" },
      children: [
        {
          path: "",
          name: "StoreManagement.BasicSettings.Page",
          component: () => import("./components/StoreManagement/BasicSettings"),
        },
        {
          path: "personalization",
          name: "StoreManagement.Personalization.Page",
          component: () => import("./components/StoreManagement/ColorSettings"),
        },

        {
          path: "about",
          name: "About",
          component: () => import("./components/StoreManagement/About"),
        },
        {
          path: "terms",
          name: "Terms",
          component: () => import("./components/StoreManagement/Terms"),
        },
        {
          path: "privacy-policy",
          name: "PrivacyPolicy",
          component: () => import("./components/StoreManagement/PrivacyPolicy"),
        },
        {
          path: "contact",
          name: "Contact",
          component: () => import("./components/StoreManagement/Contact"),
        },
      ],
    },
    {
      path: "/notification-management",
      name: "NotificationManagement.Page",
      component: () => import("./pages/NotificationManagement"),
      children: [
        {
          path: "/notification-create",
          name: "Notification.Create",
          component: () => import("./components/NotificationManagement/NotificationCreate"),
        },
        {
          path: "/notification-edit/:id",
          name: "Notification.Edit",
          component: () => import("./components/NotificationManagement/NotificationEdit"),
        },
      ],
    },
    {
      path: "/publish-app",
      name: "PublishApp.Page",
      component: () => import("./pages/PublishApp"),
    },
    {
      path: "/shopify-oauth/open",
      name: "Shopify.OAuth.Page",
      component: () => import("./pages/PublishApp"),
    },
    {
      path: "/oauth/deneme1",
      name: "Shopify.Start.Page",
      component: () => import("./pages/Start"),
    },
  ],
});

export default router;
