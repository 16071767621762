<template>

  <MemberLayout>

    <div class="page">

      <div class="nav">
        <span style="margin-right: auto">{{ t('categoryManagement') }}</span>
        <router-link class="btn btn-success" :to="{name: 'Category.Add.Page'}">
          <i class="pi pi-plus-circle"></i> {{ t('create.category') }}
        </router-link>
      </div>

      <div class="container">

        <div class="row">

          <div class="col-lg-7">
            <div class="white">
              <Tree/>
            </div>
          </div>

          <div class="col-lg-5 order-first order-md-last">

            <div class="sticky-side">

              <router-view :key="$route.path"></router-view>

            </div>

          </div>

        </div>

      </div>

    </div>

  </MemberLayout>

</template>

<script setup>
import Tree from '../components/CategoryManagement/Tree.vue'
import MemberLayout from './MemberLayout'
import useGlobal from '../composables/useGlobal'

const { t } = useGlobal()


</script>
