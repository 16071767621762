class GetProductByIdResponse {

  constructor(data) {
    this.data = data
  }

  getTitle() {
    return this.data.title
  }

  getImageUrl() {
    return this.data.image_url
  }

  getDiscounted() {
    return this.data.discounted
  }

  getListPrice() {
    return this.data.list_price
  }

  getSellPrice() {
    return this.data.sell_price
  }

}

module.exports = GetProductByIdResponse
