<template>
  <div class="readOnly">
    <div class="SocialMedia">
      <div class="item">
        <div v-if="showcaseItem.header.visible" class="widgetHeader">
          <div class="title">
            <span>{{ showcaseItem.header.title }}</span>
          </div>
        </div>
        <div class="widgetMain">
          <div class="socialMedia">
            <div v-for="(item, index) in showcaseItem.body.items" :key="index">
              <i :class="iconGenerator(item.code)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import 'remixicon/fonts/remixicon.css'

export default {
  props: {
    showcaseItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const item = reactive(props.showcaseItem)

    const iconGenerator = (code) => {
      return 'ri-' + code.toString().toLowerCase() + '-line'
    }

    return {
      item,
      iconGenerator,
    }
  },
}
</script>

<style scoped>
.socialMedia {
  display: flex;
  justify-content: space-around;
}

i {
  font-size: 2.5em;
}
</style>
