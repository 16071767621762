export default class AddSubCategoryShowResponse {

  constructor(data) {
    this.data = data
  }

  getCategoryId() {
    return this.data.category.id
  }

  getCategoryName() {
    return this.data.category.name
  }

}
