import {inject} from "vue"
import {useI18n} from "vue-i18n"
import {notify} from "@kyvg/vue3-notification"
import {useRoute, useRouter} from "vue-router"

export default function useGlobal() {
  const emitter = inject("emitter")
  const restApi = inject("restApi")

  const { t } = useI18n()

  const route = useRoute()
  const router = useRouter()

  const successNotify = (title, content) => {
    notify({
      title: title,
      text: content,
      type: "success",
    })
  }

  const errorNotify = (title, content) => {
    notify({
      title: title,
      text: content,
      type: "error",
    })
  }

  const emitRefreshCategories = () => {
    emitter.emit("refresh-categories")
  }

  return {
    route,
    router,

    emitter,
    restApi,

    successNotify,
    errorNotify,

    emitRefreshCategories,

    t,
  }
}
