<template>
  <div class="layout-menu-container">
    <AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick"/>
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu'

export default {
  props: {
    model: Array
  },

  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event)
    }
  },

  components: {
    'AppSubmenu': AppSubmenu
  }
}
</script>

<style scoped>
.layout-menu-container {
  padding: .5em 1em;
  border-radius: .3em;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.05);
}
</style>
