import {ref} from "vue";

export default function useTreeItemOptions() {

  const id = ref(null)
  const opened = ref(false)

  const openTreeItemOptions = itemId => {
    id.value = itemId
    opened.value = ! opened.value
  }

  const hideTreeItemOptions = () => {
    id.value = null
    opened.value = false
  }

  const isTreeItemOptionsShow = itemId => {
    return opened.value && id.value === itemId
  }

  return {
    openTreeItemOptions,
    hideTreeItemOptions,
    isTreeItemOptionsShow,
  }

}
