export default class GetCategoriesResponse {

  constructor(data) {
    this.data = data
  }

  /**
   * @return {Map}
   */
  getItems() {
    const items = new Map()

    for (const item of this.data.items) {
      items.set(item.id, item)
    }

    return items
  }

}
