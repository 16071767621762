<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <AppTopBar/>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2" style="margin-top: 4.6rem;">
          <AppMenu :model="menu"/>
        </div>
        <div class="col-lg-10" style="margin-inline: auto">
          <div class="layout-main" style="margin-top: 4.6rem;">
            <slot></slot>
          </div>
        </div>
        <div class="col-lg-12">
          <AppFooter/>
        </div>
      </div>
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>

</template>

<script>
import { reactive, ref } from 'vue'
import AppTopBar from './../AppTopbar.vue'
import AppMenu from './../AppMenu.vue'
import AppFooter from './../AppFooter.vue'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()

    const mobileMenuActive = ref(false)

    const menu = reactive([
      {
        label: t('design'),
        icon: 'pi pi-fw pi-mobile',
        to: { name: 'HomePage.Allshowcases.Page' },
        isActive: false,
        children: [
          {
            label: t('homePageManagement'),
            icon: 'pi pi-fw pi-home',
            to: { name: 'HomePage.Allshowcases.Page' },

          },
          {
            label: t('categoryManagement'),
            icon: 'pi pi-fw pi-th-large',
            to: { name: 'Index.Page' },
          },
          {
            label: t('personalization'),
            icon: 'pi pi-fw pi-palette',
            to: { name: 'StoreManagement.Personalization.Page' },
          },
        ],
      },
      {
        label: t('notificationManagement'),
        icon: 'pi pi-fw pi-bell',
        to: { name: 'NotificationManagement.Page' },
        isActive: false,
      },

      {
        label: t('settings'),
        icon: 'pi pi-fw pi-cog',
        to: { name: 'StoreManagement.BasicSettings.Page' },
        isActive: false,
        children: [
          {
            label: t('storeManagement'),
            icon: 'pi pi-fw pi-briefcase',
            to: { name: 'StoreManagement.BasicSettings.Page' },
          },
        ],
      },
      {
        label: t('publish'),
        icon: 'pi pi-fw pi-share-alt',
        to: { name: 'PublishApp.Page' },
        isActive: false,
      },
      {
        label: t('helpSupport'),
        icon: 'pi pi-fw pi-info-circle',
        to: '',
        isActive: false,
        children: [
          {
            label: t('help'),
            icon: 'pi pi-fw pi-question-circle',
            to: '',
          },
          {
            label: t('contact'),
            icon: 'pi pi-fw pi-phone',
            to: '',
          },

        ],
      },
    ])



    return {

      menu,

      t,

      mobileMenuActive,

    }
  },

  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    'AppFooter': AppFooter,
  },
}
</script>
