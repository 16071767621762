<template>
  <div>
    <div v-if="isLoading" class="loading">
      <div class="spinner"></div>
      <div class="text">{{ t('loading.categories') }}</div>
    </div>

    <div v-else>
      <div v-if="hasError">
        <h3>{{ t('tryAgain') }}</h3>
        <p>{{ t('categoriesNotLoaded') }}</p>
      </div>

      <div v-else>
        <FilterCategory
          v-model:name="filterConfig.name"
          v-model:status="filterConfig.status"
        />


        <div v-if="!categories.length" style="padding: 3em">
          <h5>{{ t('notFound') }}</h5>
          <p>{{ t('noResultsFoundMatching') }}</p>
        </div>

        <Tree :value="categories">
          <template v-slot="{ node, index, path, tree }">
            <div :key="index" class="dragItem">
              <div class="parent" :class="{ passive: !node.is_active }">
                <div class="rowNumber">
                  <span style="width: 100px; padding: 1em 0.5em">{{
                      node.row_number
                    }}</span>
                </div>

                <div v-if="node.image_url" class="image">
                  <img :src="node.image_url" :alt="t('categoryImg')"/>
                </div>

                <div class="name">{{ node.name }}</div>

                <div class="flags">
                  <div
                    :class="node.is_active ? 'green' : 'gray'"
                    :title="
                      node.is_active
                        ? t('doPassiveCategory')
                        : t('doActiveCategory')
                    "
                  >
                    <a @click="toggleCategoryVisible(node)">
                      <i class="ri-flag-fill"></i>
                    </a>
                  </div>
                </div>

                <div v-if="node.children.length > 0" class="expand">
                  <b @click="tree.toggleFold(node, path)">
                    <i
                      :class="
                        node.$folded
                          ? 'ri-arrow-down-s-line'
                          : 'ri-arrow-up-s-line'
                      "
                    ></i>
                  </b>
                </div>

                <div class="optionsWrapper">
                  <button
                    class="options"
                    :title="t('actions')"
                    @click="openTreeItemOptions(node.id)"
                  >
                    <i class="pi pi-ellipsis-v"></i>
                  </button>
                  <div
                    v-click-outside="hideTreeItemOptions"
                    tabindex="0"
                    v-if="isTreeItemOptionsShow(node.id)"
                    class="optionsPop"
                  >
                    <div class="creat">
                      <a
                        @click="addSubCategory(node.id)"
                        :title="t('createSubCategory')"
                      >
                        <i class="pi pi-tags"></i> {{ t('createSubCategory') }}
                      </a>
                    </div>
                    <div class="edit">
                      <a
                        @click="editCategory(node.id)"
                        :title="t('editCategory')"
                      >
                        <i class="pi pi-pencil"></i> {{ t('editCategory') }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Tree>
      </div>
    </div>
  </div>
</template>

<script>
import { Tree, Fold } from 'he-tree-vue'
import useGetCategories from '../../composables/useGetCategories'
import useCategoryFilters from '../../composables/useCategoryFilters'
import useCategoriesForTree from '../../composables/useCategoriesForTree'
import FilterCategory from '../../components/CategoryManagement/FilterCategory.vue'
import { restToggleCategoryVisible } from '../../service/RestApi'
import useTreeItemOptions from '../../composables/useTreeItemOptions'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default {
  components: {
    Tree: Tree.mixPlugins([Fold]),
    FilterCategory,
  },

  setup(props, { emit }) {
    const router = useRouter()

    const { t } = useI18n()

    const { openTreeItemOptions, hideTreeItemOptions, isTreeItemOptionsShow } =
      useTreeItemOptions()

    const toggleCategoryVisible = (node) => {
      restToggleCategoryVisible(node.id).then(() => {
        node.is_active = ! node.is_active
        router.go()
      })
    }

    const { hasError, errorMessage, isLoading, status, categories } =
      useGetCategories()

    const { filterConfig, filteredCategories } = useCategoryFilters(categories)

    const { categoriesForTree } = useCategoriesForTree(filteredCategories)

    const addSubCategory = (id) => {
      hideTreeItemOptions()

      router.push({
        name: 'Category.AddSub.Page',
        params: {
          id: id,
        },
      })
    }

    const editCategory = (id) => {
      hideTreeItemOptions()

      router.push({
        name: 'Category.Edit.Page',
        params: {
          id: id,
        },
      })
    }

    return {
      hasError,
      errorMessage,
      isLoading,

      openTreeItemOptions,
      hideTreeItemOptions,
      isTreeItemOptionsShow,

      addSubCategory,
      editCategory,

      toggleCategoryVisible,

      status,
      categories: categoriesForTree,
      filterConfig,

      t,
    }
  },
}
</script>

<style scoped>
.dragItem .parent {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  margin: 3px;
  gap: 0.3em;
}

.dragItem .move {
  cursor: move;
}

.dragItem .image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.parent + .dragItem,
.child + .child {
  margin-left: 2em;
}

.dragItem .name {
  margin-right: auto;
  font-weight: 500;
}

.dragItem .childName {
  margin-right: auto;
  font-weight: normal;
}

.dragItem .rowNumber input {
  width: 65px;
  margin-right: 1em;
  font-size: 0.9em;
  font-weight: bold;
  color: gray;
}

.options {
  position: relative;
  background-color: transparent;
  border: none;
}

.optionsPop {
  position: absolute;
  background-color: #fff;
  top: 5px;
  right: 5px;
  box-shadow: 1px 1px 3px lightgray;
  z-index: 1;
  font-size: 0.9em;
}

.optionsPop div {
  padding: 0.5em;
}

.optionsPop div:hover {
  background: #eee;
}

.flags {
  display: flex;
  column-gap: 1em;
  position: relative;
}

.flags .gray {
  color: var(--danger);
}

.flags .green {
  color: var(--success);
}

.expand {
  cursor: pointer;
  margin: 0 5px;
}

.dragItem {
  position: relative;
}

.parent.passive img {
  filter: grayscale(100%);
}
</style>
