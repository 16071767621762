<template>
  <div class="readOnly">
    <div class="Layout2">
      <div class="item">
        <div class="widgetHeader" v-if="showcaseItem.header.visible">
          <div class="title">
            <span v-if="showcaseItem.header.visible">
              {{ showcaseItem.header.title }}
            </span>
          </div>
          <div class="subtitle">
            <span
              v-if="showcaseItem.header.right.visible"
              :style="{ color: color.primary_color }"
            >
              {{ showcaseItem.header.right.title }}
            </span>
          </div>
        </div>
        <div class="widgetMain">
          <div class="products">
            <div
              v-for="(page, index) in showcaseItem.body.pages"
              :key="index"
              class="page"
            >
              <div
                :class="{ lowStock: item.total_inventory < 1 }"
                class="product"
                v-for="(item, index) in page.items"
                :key="index"
              >
                <div class="image">
                  <img :src="item.image_url" />
                </div>
                <div class="title-and-brand">
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="brand" :style="{ color: color.primary_color }">
                    {{ item.brand }}
                  </div>
                </div>

                <div class="price">
                  <div class="del">
                    <del v-if="item.discounted">{{ item.list_price }}</del>
                  </div>

                  <div
                    class="current"
                    :style="{ backgroundColor: color.secondary_color }"
                  >
                    {{ item.sell_price }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showcaseItem: {
      type: Object,
      required: true,
    },
    color: Object,
  },
};
</script>

<style scoped>
.Layout2 {
  overflow-x: scroll;
}

.Layout2 .products {
  display: flex;
  gap: 1em;
}

.Layout2 .products .product {
  display: flex;
  width: 300px;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
}

.Layout2 .products .image img {
  width: 100px;
  border-radius: 1em;
}

.title-and-brand {
  text-align: center;
}

.price div {
  width: max-content;
}

.page {
  display: grid;
  gap: 1.5em;
}
</style>
