<template>
  <MemberLayout>
    <div class="page">
      <div class="nav">
        <span style="margin-right: auto">{{ t('homePageManagement') }}</span>
        <router-link
          :to="{ name: 'HomePage.Add.Page' }"
          class="btn btn-success"
        >
          <i class="pi pi-plus-circle"></i>
          {{ t('create.showcase') }}
        </router-link>
        <router-link
          :to="{ name: 'HomePage.Allshowcases.Page' }"
          class="btn btn-primary"
        >
          {{ t('allShowcases') }}
        </router-link>
      </div>

      <div class="loading" v-if="isLoading">
        <div class="spinner"></div>
        {{ t('loading.loading') }}
      </div>

      <div v-else>

        <div v-if="hasError">
          <h3>{{ t('simulationNotLoaded') }}</h3>
          <p>{{ t('tryAgain') }}</p>
        </div>

        <div v-else class="container">
          <div class="row">
            <div class="col-lg-8">
              <router-view :collections="collections"/>
            </div>

            <div class="col-lg-4">
              <div class="sticky-side">
                <Simulation/>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </MemberLayout>
</template>

<script>
import MemberLayout from './MemberLayout'
import Simulation from '../components/HomePageManagement/Simulation.vue'
import useGlobal from '../composables/useGlobal'
import { onMounted, ref } from 'vue'

export default {
  components: {
    MemberLayout,
    Simulation
  },

  setup() {
    const { restApi, t, errorNotify } = useGlobal()

    const collections = ref([])

    const isLoading = ref(true)
    const hasError = ref(false)

    onMounted(() => {

      restApi
        .fetchCollections()
        .then((response) => {
          collections.value = response.getItems()
          isLoading.value = false
        })
        .catch(() => {

          isLoading.value = false
          hasError.value = true

          errorNotify(
            'Collections could not be loaded',
            'Please try again later'
          )
        })
    })

    return {
      t,

      collections,

      isLoading,
      hasError
    }
  }
}
</script>
