import axios from 'axios'
import isEmpty from 'is-empty'

import AddSubCategoryShowResponse from './RestApi/AddSubCategoryShowResponse'
import EditCategoryShowResponse from './RestApi/EditCategoryShowResponse'
import GetCategoriesResponse from './RestApi/GetCategoriesResponse'
import GetCollectionsResponse from './RestApi/GetCollectionsResponse'
import AddShowcaseResponse from './RestApi/AddShowcaseResponse'
import SortProductsResponse from './RestApi/SortProductsResponse'
import GetAboutUsResponse from './RestApi/GetAboutUsResponse'
import GetAllProductsResponse from './RestApi/GetAllProductsResponse'
import {EditCategorySubmitRequest} from './RestApi/EditCategorySubmit'
import GetNotificationsResponse from './RestApi/GetNotificationsResponse'
import GetColorSetsResponse from './RestApi/GetColorSetsResponse'
import GetProductByIdResponse from './RestApi/GetProductByIdResponse'

export class RestApi {
  constructor(baseUrl, token) {
    this.baseUrl = baseUrl

    this.token = jwtToken

  }

  /**
   * @return {Promise<GetCollectionsResponse>}
   */
  fetchCollections() {
    return this._post('/get-collections').then(
      (response) => new GetCollectionsResponse(response),
    )
  }

  /**
   * @return {Promise<GetCategoriesResponse>}
   */
  fetchCategories() {
    return this._post('/category/get-categories').then(
      (response) => new GetCategoriesResponse(response),
    )
  }

  fetchSimulation() {
    return this._post('/showcase/get-simulation')
  }

  isValidateToken(userToken) {
    return this._post('/store/is-valid-token', {
      token: userToken,
    })
  }

  subscription(domain) {
    return this._post('/subscription', {domain: domain}).then(response => {
      console.log(response)
      return response.response.data.appSubscriptionCreate.confirmationUrl
    })
  }

  fetchShowcasesItems() {
    return this._post('/showcase/list')
  }

  /**
   * @return {Promise<GetAllProductsResponse>}
   */
  fetchProducts() {
    return this._post('/product/get-all-products').then(
      (response) => new GetAllProductsResponse(response),
    )
  }

  fetchIcons() {
    return this._post('/showcase/get-icons').then(response => response.icons)
  }

  /**
   * @return {Promise<GetAboutUsResponse>}
   */
  fetchAboutUs() {
    return this._post('/profile/get-about-us').then(
      (response) => new GetAboutUsResponse(response),
    )
  }

  /**
   * @param {string} appLink
   * @param {string} termsLink
   *
   * @return {Promise<void>}
   */
  editAboutUs(appLink, termsLink) {
    return this._post('/profile/edit-about-us', {
      app_link: appLink,
      terms_link: termsLink,
    })
  }

  showcaseAdd(params) {
    return this._post('/showcase/add', params).then(
      (response) => new AddShowcaseResponse(response),
    )
  }

  editShowcase(params) {
    return this._post('/showcase/edit-submit', params)
  }

  addShowcaseItemChild(data) {
    return this._post('/showcase/add-child', data)
  }

  editShowcaseItemChildSubmit(data) {
    return this._post('/showcase/edit-child-submit', data)
  }

  deleteShowcaseItemChild(childId) {
    return this._post('/showcase/delete-child', {
      showcase_item_child_id: childId,
    })
  }

  /**
   *
   * @param collectionId
   * @param itemCount
   * @param sortKey
   * @param type
   * @param pageCount
   * @return {Promise<SortProductsResponse>}
   */
  sortProducts(collectionId, itemCount, sortKey, type = '', pageCount) {
    return this._post('/showcase/sort-products', {
      collection_id: collectionId,
      item_count: itemCount,
      sort_key: sortKey,
      type: type,
      page_count: pageCount,
    }).then((response) => {
      return new SortProductsResponse(response)
    })
  }

  getAllNotifications(start, length) {
    return this._post('/sendheap/get-notifications', {
      start: start,
      length: length,
    }).then((response) => {
      return new GetNotificationsResponse(response)
    })
  }

  addTask(title, content, targetUrl, iconUrl, imageUrl) {
    return this._post('/sendheap/add-task', {
      title: title,
      content: content,
      target_url: targetUrl,
      icon_url: iconUrl,
      image_url: imageUrl,
    })
  }

  restUpShowcaseItem(id) {
    return this._post('/showcase/up', {showcase_item_id: id})
  }

  restDownShowcaseItem(id) {
    return this._post('/showcase/down', {showcase_item_id: id})
  }

  restShowcaseToggleVisible(id) {
    return this._post('/showcase/toggle-visible', {showcase_item_id: id})
  }

  getProductsByCollectionsId(id, type = '', pageCount, itemCount) {
    return this._post('/showcase/get-products-by-collection-id', {
      collection_id: id,
      type: type,
      page_count: pageCount,
      item_count: itemCount,
    })
  }

  getShowcaseById(id) {
    return this._post('/showcase/get-showcase-by-id', {
      showcase_item_id: id,
    })
  }

  addCategorySubmit(name, imageName, imageData, isActive, shopifyCollectionId) {
    return this._post('/category/add-submit', {
      name: name,
      image_name: imageName,
      image_data: imageData,
      is_active: isActive,
      shopify_collection_id: shopifyCollectionId,
    })
  }

  /**
   * @param {string} parentId
   *
   * @return {Promise<AddSubCategoryShowResponse>}
   */
  addSubCategoryShow(parentId) {
    return this._post('/category/add-sub-show', {
      parent_id: parentId,
    }).then((data) => new AddSubCategoryShowResponse(data))
  }

  /**
   * @param {string} parentId
   * @param {string} name
   * @param {boolean} isActive
   * @param {string} shopifyCollectionId
   *
   * @return {Promise<void>}
   */
  addSubCategorySubmit(parentId, name, isActive, shopifyCollectionId) {
    return this._post('/category/add-sub-submit', {
      parent_id: parentId,
      name: name,
      is_active: isActive,
      shopify_collection_id: shopifyCollectionId,
    })
  }

  /**
   *
   * @return {Promise<GetColorSetsResponse>}
   */
  getColorSets() {
    return this._post('/store/get-color-sets').then((response) => {
      return new GetColorSetsResponse(response)
    })
  }

  /**
   *
   * @param colorName
   * @return {Promise<{}>}
   */
  setColorSet(colorName) {
    return this._post('/store/set-color-set', {color_name: colorName})
  }

  /**
   * @param {string} id
   *
   * @return {Promise<EditCategoryShowResponse>}
   */
  editCategoryShow(id) {
    return this._post('/category/edit-show', {
      category_id: id,
    }).then((response) => new EditCategoryShowResponse(response))
  }

  /**
   * @param {EditCategorySubmitRequest} request
   *
   * @return {Promise<void>}
   */
  editCategorySubmit(request) {
    return this._post('/category/edit-submit', request.toParams())
  }

  _post(endpoint, data = {}) {
    const url = this.baseUrl + endpoint

    const headers = {
      'X-Lang-Code': 'en',
    }
    console.log(jwtToken)
    headers['X-Token'] = jwtToken

    console.log('making request', url, data, headers)

    return axios.post(url, data, {
      headers: headers,
    }).then((response) => response.data).catch((error) => {
      console.log('axios hatası meydana geldi.')
      console.log(error.config)

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)

        // burada düşmesi demek 500 hatası geldi demektir, backend ya 200 ya da 500 dönüyor

        // {
        //    'code': 'INTERNAL_SERVER_ERROR',
        //    'data': {
        //      'message': 'blabla'
        //    }
        // }

        throw error.response.data.data.message
      }

      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js

        console.log(error.request)

        throw 'Servis ile iletişim kurulamadı.'
      }

      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)

      throw 'Bilinmeyen bir hata meydana geldi.'
    }).then((response) => {
        console.log('response received', response)

        if (response.code === 'ERROR') {

          // if (response.data.message === 'UNAUTHORIZED') {
          //   localStorage.removeItem('accessToken')
          // }
          throw response.data.message
        }

        if (typeof response.data === 'undefined') {
          return response
        }
        return response.data
      },
    )
  }
}

export function makePost(endpoint, data = {}) {
  return axios.post(process.env.VUE_APP_BACKEND_URL + endpoint, data, {
    headers: {
      'X-Token': jwtToken,
      'X-Lang-Code': 'en',
    },
  }).then((response) => {
    return response.data.data
  })
}

export function restUpShowcaseItem(id) {
  return makePost('/showcase/up', {showcase_item_id: id})
}

export function restDownShowcaseItem(id) {
  return makePost('/showcase/down', {showcase_item_id: id})
}

export function restAddShowcase(data) {
  return makePost('/showcase/add', data)
}

export function restShowcaseIndex() {
  return makePost('/showcase/index')
}

export function restEditCategorySubmit(data) {
  return makePost('/category/edit-submit', data)
}

export function restToggleCategoryVisible(id) {
  return makePost('/category/toggle-visible', {category_id: id})
}

export function restGetCollections() {
  return makePost('/get-collections')
}
