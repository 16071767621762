export class EditCategorySubmitRequest {
  constructor() {
    this.categoryId = ''

    this.parentId = ''
    this.name = ''
    this.isActive = true
    this.rowNumber = 1
    this.shopifyCollectionId = ''

    this.imageName = ''
    this.imageData = ''
    this.imageHasChanged = false
  }

  toParams() {
    return {
      category_id: this.categoryId,

      parent_id: this.parentId,
      name: this.name,
      is_active: this.isActive,
      row_number: this.rowNumber,
      shopify_collection_id: this.shopifyCollectionId,

      image_name: this.imageName,
      image_data: this.imageData,
      image_has_changed: this.imageHasChanged,
    }
  }

  setCategoryId(categoryId) {
    this.categoryId = categoryId
  }

  setParentId(parentId) {
    this.parentId = parentId
  }

  setName(name) {
    this.name = name
  }

  setIsActive(isActive) {
    this.isActive = isActive
  }

  setRowNumber(rowNumber) {
    this.rowNumber = rowNumber
  }

  setShopifyCollectionId(shopifyCollectionId) {
    this.shopifyCollectionId = shopifyCollectionId
  }

  setImageName(imageName) {
    this.imageName = imageName
  }

  setImageData(imageData) {
    this.imageData = imageData
  }

  setImageHasChanged(imageHasChanged) {
    this.imageHasChanged = imageHasChanged
  }
}
