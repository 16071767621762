import { computed, reactive } from 'vue'

export default function useCategoryFilters(categories) {

  const filterConfig = reactive({
    name: '',
    status: 'all',
  })

  const filteredCategories = computed(() => {

    const filteredCategories = new Map()

    categories.value.forEach(category => {

      if (shouldShow(filterConfig, category)) {

        for (const withParentCategory of getWithParentCategories(categories, category)) {

          filteredCategories.set(withParentCategory.id, withParentCategory)

        }

      }

    })

    return [...filteredCategories.values()]

  })

  return {
    filterConfig,
    filteredCategories,
  }

}

function shouldShowByName(category, name) {
  return category.name.toLowerCase().search(name.toLowerCase()) !== -1
}

function shouldShowByStatus(category, status) {

  switch (status) {
    case '0':
      return category.is_active === false
    case '1':
      return category.is_active === true
    default:
      return true
  }

}

function getWithParentCategories(categories, category, items = []) {

  items.push(category)

  if (category.parent_id === null) {
    return items
  }

  return getWithParentCategories(categories, categories.value.get(category.parent_id), items)

}

function shouldShow(filterConfig, category) {

  let applyNameFilter = false
  let applyStatusFilter = false

  if (filterConfig.name.length > 0) {
    applyNameFilter = true
  }

  if (filterConfig.status !== 'all') {
    applyStatusFilter = true
  }

  if ( ! applyNameFilter && ! applyStatusFilter) {
    return true
  }

  if (applyNameFilter && ! applyStatusFilter) {
    return shouldShowByName(category, filterConfig.name)
  }

  if ( ! applyNameFilter && applyStatusFilter) {
    return shouldShowByStatus(category, filterConfig.status)
  }

  return shouldShowByName(category, filterConfig.name) || shouldShowByStatus(category, filterConfig.status)

}
