import {computed} from "vue";

export default function useCategoriesForTree(categories) {

  const getParentCategories = () => {
    return categories.value.filter(item => item.parent_id === null)
  }

  const getChildCategories = parentId => {
    return categories.value.filter(item => item.parent_id === parentId)
  }

  const getItems = childs => {

    return childs.map(child => {

      return {
        id: child.id,
        name: child.name,
        is_active: child.is_active,
        row_number: child.row_number,
        image_url: child.image_url,
        $folded: true, // tree plugininde kapalı olarak görünmesi için
        children: getItems(getChildCategories(child.id)),
      }

    })

  }

  const categoriesForTree = computed(() => {
    return getItems(getParentCategories())
  })

  return {
    categoriesForTree
  }

}
