export default {
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Out"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving..."])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "passive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "parentCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent Category"])},
  "subCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Category Name"])},
  "enterCategoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter category name"])},
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish App"])},
  "helpSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help & Support"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "categoryImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category image"])},
  "categoryCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Collections"])},
  "isCategoryStatusActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is category status active?"])},
  "rowNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row number"])},
  "showcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showcase"])},
  "create": {
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Category"])},
    "showcase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Home Page"])},
    "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Collection"])},
    "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Notification"])}
  },
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Error"])},
  "maxFileSizeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is big than maximum size"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
  "ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratio"])},
  "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select File"])},
  "selectFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Folder"])},
  "selectImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Image"])},
  "maxFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum file size"])},
  "categoryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category Name"])},
  "homePageManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page"])},
  "allShowcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See All Pages"])},
  "loading": {
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Categories"])},
    "showcases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Showcases"])},
    "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Collections"])},
    "simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Simulation"])},
    "horizontal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Horizontal"])},
    "layout1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Layout 1"])},
    "layout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Layout 2"])},
    "layout3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Layout 3"])},
    "vertical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Vertical"])},
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Social Medias"])}
  },
  "primaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Color"])},
  "secondaryColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Color"])},
  "someWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "searchResultsNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results not found"])},
  "noResultsFoundMatching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found matching"])},
  "doActiveCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to active this category?"])},
  "doPassiveCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to passive this category?"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "createSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Sub Category"])},
  "editCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Category"])},
  "showcaseImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page Item Image"])},
  "IncreaseYourSalesWithMobileShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase your sales with Mobile Shopping!"])},
  "NextGenerationMobileAppPlatformExpandsYourReach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The next generation mobile app platform expands your reach and helps you succeed in the mobile world."])},
  "NoCodingRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No coding required, you can get a design that your customers will love with a handy setting screen."])},
  "welcomeFigmobi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Figmobi"])},
  "SignInToContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to continue"])},
  "yourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Address"])},
  "yourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Password"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "rightTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Title"])},
  "headerIsVisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header is visible?"])},
  "rightHeaderIsVisible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right Header is visible?"])},
  "notificationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "allNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Notifications"])},
  "memberName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Name"])},
  "memberSurName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Sur Name"])},
  "memberEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member E-Mail"])},
  "notificationContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Content"])},
  "targetUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Url"])},
  "imgUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Url"])},
  "dynamicSelectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic Selectors"])},
  "storeManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Settings"])},
  "showcaseCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Collections"])},
  "showingItemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing item count"])},
  "sort": {
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "backToOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to old"])},
    "increasedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased Price"])},
    "decreasingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decreasing Price"])},
    "alphabeticalAZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetical A-Z"])},
    "alphabeticalZA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetical Z-A"])}
  },
  "contentSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Source"])},
  "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
  "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "pageCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Count"])},
  "elementCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element Count"])},
  "columnCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column Count"])},
  "newTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Title"])},
  "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
  "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon Url"])},
  "AreYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure delete?"])},
  "AreYouSureDeleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure delete this item?"])},
  "basicSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Settings"])},
  "chooseShowcaseType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Home Page Type"])},
  "colorSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Settings"])},
  "selectCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Collection"])},
  "selectMinCollection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select minimum a collection"])},
  "required": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter title"])},
    "rightTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter right title"])},
    "categoryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter category name"])},
    "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a image"])}
  },
  "storeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Name"])},
  "storeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Id"])},
  "storeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Status"])},
  "currentLanguages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Languages"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "storeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Logo"])},
  "storeAppLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store App Link"])},
  "storeTermsLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Terms Link"])},
  "socialMedias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Medias"])},
  "notificationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification List"])},
  "createNewNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Notification"])},
  "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try again"])},
  "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found"])},
  "headerRightShowcaseCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header Right  Collection"])},
  "showcasesNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showcases not loaded"])},
  "categoriesNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories not loaded"])},
  "detailNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail not loaded"])},
  "itemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Count"])},
  "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Item"])},
  "choiceThemeColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Theme Color"])},
  "perPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Page"])},
  "sourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Type"])},
  "personalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalization"])},
  "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header Title"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
  "enterYourAppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your app name"])},
  "storeLangs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store Langs"])},
  "textColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Color"])},
  "onLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Live"])},
  "categoryCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category created"])},
  "categoryAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category successfully added"])},
  "categoryNotCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category not created"])},
  "categoryCreatingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category creating error"])},
  "changesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saved"])},
  "changesSavedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes successfully saved"])},
  "changesNotSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes not saved"])},
  "changesNotSavedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes saving error"])},
  "showcaseCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page created"])},
  "showcaseCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page successfully created"])},
  "showcaseNotCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page not created"])},
  "showcaseNotCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page creating error"])},
  "showcaseItemDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item deleted"])},
  "showcaseItemDeletedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item successfully deleted"])},
  "showcaseItemNotDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item not deleted"])},
  "showcaseItemNotDeletedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item deleting error"])},
  "productsNotFetched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products not fetched"])},
  "productsNotFetchedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products fetching error"])},
  "maxItemCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Item Count"])},
  "maxItemCountDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only select Item Count Lenght"])},
  "showcaseItemUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item updated"])},
  "showcaseItemUpdatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item successfully updated"])},
  "showcaseItemNotUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item not updated"])},
  "showcaseItemNotUpdatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item updating error"])},
  "showcaseItemCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item created"])},
  "showcaseItemCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item successfully created"])},
  "showcaseItemNotCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item not created"])},
  "showcaseItemNotCreatedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Page item creating error"])}
}