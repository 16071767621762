export default class GetAllProductsResponse {

  constructor(response) {
    this.response = response
  }

  /**
   * @return {GetAllProductsItem[]}
   */
  getItems() {
    return this.response.items.map(item => new GetAllProductsItem(item))
  }

}

class GetAllProductsItem {

  constructor(item) {
    this.item = item
  }

  getId() {
    return this.item.id
  }

  getTitle() {
    return this.item.title
  }

  getImageUrl() {
    return this.item.image_url
  }

}
