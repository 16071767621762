export default class SortProductsResponse {

  constructor(data) {
    this.data = data
  }

  /**
   * @returns {string}
   */
  getProducts() {
    return (this.data.items.map(item => new Product(item)))
  }

}

class Product {
  constructor(item) {
    this.item = item
  }

  getProductId() {
    return this.item.id
  }

  getTitle() {
    return this.item.title
  }

  getBrand() {
    return this.item.brand
  }

  getListPrice() {
    return this.item.list_price
  }

  getSellPrice() {
    return this.item.sell_price
  }

  getImageUrl() {
    return this.item.image_url
  }
}
