<template>
  <div class="layout-footer">
    <small>
      Copyright © {{ currentYear }} <b>Fig.mobi</b>
    </small>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
b {
  color: gray;
}

small {
  font-size: .9em;
}
</style>
