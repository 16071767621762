export default class GetAboutUsResponse {

  constructor(data) {
    this.data = data
  }

  /**
   * @return {string}
   */
  getStoreId() {
    return this.data.store_id
  }

  /**
   * @return {string}
   */
  getStoreName() {
    return this.data.store_name
  }

  /**
   * @return {string}
   */
  getAppLink() {
    return this.data.app_link
  }

  /**
   * @return {string}
   */
  getTermsLink() {
    return this.data.terms_link
  }

  /**
   * @return {string}
   */
  getStoreLogoLink() {
    return this.data.store_logo_link
  }

}
