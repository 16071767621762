<template>
  <div :class="{ blur: scroll === false }">

    <div v-if="isLoading" class="loading">
      <div class="spinner"></div>
      <div class="text">{{ t('loading.simulation') }}</div>
    </div>

    <div v-else>

      <div v-if="hasError">
        <h3>{{ t('simulationNotLoaded') }}</h3>
        <p>{{ t('tryAgain') }}</p>
      </div>

      <div v-else class="simulation">
        <div class="phone" :class="{ scrollFalse: scroll === false }">
          <div class="phoneHeader" id="phoneHeader">
            <div class="nav">
              <div class="center">
                <h5>{{ head.title }}</h5>
                <span>{{ head.description }}</span>
              </div>
            </div>
          </div>
          <div class="phoneMain">
            <div class="showcases">
              <div
                v-for="(item, index) in displayableItems"
                :key="index + item.id"
                :id="'a' + item.id"
              >
                <div
                  :class="{
                  deactive: !item.is_active,
                  focus: item.id === route.params.id,
                }"
                  class="item"
                >
                  <ButtonSet
                    @edit="editClick(item.id)"
                    @toggle-visible="toggleVisible(item.id)"
                    @move-down="moveDown(item.id)"
                    @move-up="moveUp(item.id)"
                    :show-edit-link="showEditLink(item.id)"
                    :show-up-button="showUpButton(item.id)"
                    :show-down-button="showDownButton(item.id)"
                    class="btnSet"
                  />

                  <HORIZONTAL
                    :color="currentColor"
                    :showcase-item="item"
                    v-if="item.type === 'HORIZONTAL'"
                  />

                  <VERTICAL
                    :color="currentColor"
                    :showcase-item="item"
                    v-if="item.type === 'VERTICAL'"
                  />

                  <LAYOUT1
                    :color="currentColor"
                    :showcase-item="item"
                    v-if="item.type === 'LAYOUT1'"
                  />

                  <LAYOUT2
                    :color="currentColor"
                    :showcase-item="item"
                    v-if="item.type === 'LAYOUT2'"
                  />

                  <LAYOUT3
                    :color="currentColor"
                    :showcase-item="item"
                    v-if="item.type === 'LAYOUT3'"
                  />

                  <SOCIAL_MEDIAS
                    :showcase-item="item"
                    v-if="item.type === 'SOCIAL_MEDIAS'"
                  />
                </div>

              </div>

            </div>
          </div>

<!--          <div class="phoneFooter">-->
<!--            <small style="font-size: 10px"> Powered by <b>Figmobi</b> </small>-->
<!--          </div>-->

          <div class="phoneNavbar">
            <ul>
              <li>
                <img src="../../assets/img/phone-navbar/house.svg">
                <span>{{ t('home') }}</span>
              </li>
              <li>
                <img src="../../assets/img/phone-navbar/category.svg">
                <span>{{ t('categories') }}</span>
              </li>
              <li>
                <img src="../../assets/img/phone-navbar/cart.svg">
                <span>{{ t('cart') }}</span>
              </li>
              <li>
                <img src="../../assets/img/phone-navbar/fav.svg">
                <span>{{ t('favorites') }}</span>
              </li>
              <li>
                <img src="../../assets/img/phone-navbar/person.svg">
                <span>{{ t('profile') }}</span>
              </li>
            </ul>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import ButtonSet from './SimulationParts/ButtonSet'
import useGlobal from '../../composables/useGlobal'

import HORIZONTAL from './SimulationParts/HORIZONTAL'
import LAYOUT1 from './SimulationParts/LAYOUT1'
import LAYOUT2 from './SimulationParts/LAYOUT2'
import VERTICAL from './SimulationParts/VERTICAL'
import LAYOUT3 from './SimulationParts/LAYOUT3'
import SOCIAL_MEDIAS from './SimulationParts/SOCIAL_MEDIAS'
import useGetSimulationData from '../../composables/HomePageManagement/useGetSimulationData'

export default {
  components: {
    ButtonSet,
    SOCIAL_MEDIAS,
    LAYOUT3,
    VERTICAL,
    LAYOUT2,
    LAYOUT1,
    HORIZONTAL,
  },

  props: {
    scroll: {
      type: Boolean,
      default: true,
    },
  },

  setup: function () {

    const { t, route, router, restApi, emitter, errorNotify } = useGlobal()

    const { isLoading, hasError, displayableItems, reloadSimulationData } = useGetSimulationData()

    emitter.on('set-color', (data) => {
      currentColor.value = data
    })

    const head = ref({
      title: 'Ana Sayfa',
      description: 'Hoş geldiniz',
    })

    const editClick = (itemId) => {
      router.push({ name: 'HomePage.Allshowcases.Page' }).then(() => {
        router.push({
          name: 'HomePage.Edit.Page',
          params: { id: itemId },
        })
      })
    }

    const toggleVisible = (itemId) => {
      restApi.restShowcaseToggleVisible(itemId).then(() => {
        reloadSimulationData()
      })
    }

    const moveDown = (itemId) => {
      restApi.restDownShowcaseItem(itemId).then(() => {
        reloadSimulationData()
      })
    }

    const moveUp = (itemId) => {
      restApi.restUpShowcaseItem(itemId).then(() => {
        reloadSimulationData()
      })
    }

    const showEditLink = (itemId) => {
      return route.params.id !== itemId
    }

    const showUpButton = (itemId) => {
      return displayableItems.value[0].id !== itemId
    }

    const showDownButton = (itemId) => {
      return displayableItems.value[displayableItems.value.length - 1].id !== itemId
    }

    const currentColor = ref({})

    onMounted(() => {

      restApi.getColorSets().then((res) => {
        currentColor.value = res.getColors().filter((color) => color.getSelected() === true)[0].item
      }).catch(() => {
        errorNotify(
          'Failed to get color sets.',
          'Please check your network connection.',
        )
      })

      if (useI18n().locale.value === 'tr') {
        head.value.title = 'Ana Sayfa'
        head.value.description = 'Hoş geldiniz'
      }

      if (useI18n().locale.value === 'en') {
        head.value.title = 'Home'
        head.value.description = 'Welcome'
      }
    })

    return {
      t,

      head,

      isLoading,
      hasError,

      editClick,
      toggleVisible,

      moveDown,
      moveUp,

      showEditLink,
      showUpButton,
      showDownButton,

      route,

      displayableItems,

      currentColor,
    }
  },
}
</script>

<style scoped>
.simulation {
  overflow: hidden;
  max-width: 375px;
  position: relative;
}

.simulation::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 18px;
  background-color: #5c5c5c;
  top: 0;
  left: 20%;
  z-index: 1;
  border-radius: 0 0 20px 20px;
}

.phone {
  height: 650px;
  border: 6px solid #5c5c5c;
  border-radius: 40px;
  overflow-y: auto;
  position: relative;
  z-index: 0;
  -webkit-box-shadow: 2px 2px 6px #d3d3d3;
  box-shadow: 2px 2px 6px #d3d3d3;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.phoneHeader,
.phoneMain,
.phoneFooter {
  padding: 1em;
}

.phoneMain {
  padding: 0.4em;
  flex: 1;
  overflow-y: scroll !important;
}

.phoneHeader .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  padding-top: 0.9em;
  padding-bottom: 0;
  margin-bottom: 0;
}

.phoneHeader .nav h5 {
  margin-bottom: 0;
  font-size: 1.2em;
}

.phoneMain {
  overflow: hidden;
  font-size: 0.8em;
}

.phoneFooter {
  margin-bottom: 1em;
  margin-left: 1em;
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);

  pointer-events: none;
  cursor: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
  opacity: 0.2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to bottom, #555, #555);
}

.Layout1 .products .image img {
  height: 230px !important;
  width: 100%;
  object-fit: cover;
}

.scrollFalse {
  overflow-y: hidden;
}

.deactive {
  position: relative;
  filter: grayscale(100%) opacity(50%);
}

.item {
  position: relative;
  margin-bottom: 1em;
}

.item .btnSet {
  position: absolute;
  top: 0;
  display: none;
}

.item:hover {
  box-shadow: 0 0 0 1px #d3d3d3;
}

.item:hover .btnSet {
  display: block;
}

.focus {
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  padding: 0.1em 0.3em;
  margin: 3em 0;
  border: 2px solid limegreen;
  box-shadow: 0px 0px 5px limegreen;
  scroll-margin-top: 100px;
}

.focus::before {
  content: '';
  inset: 0;
  z-index: -1;
  position: absolute;
  outline: 10px solid limegreen;
  outline-offset: 1px;
  animation: blink 1.5s forwards;
  border-radius: 5px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none !important;
  }
}


.phoneNavbar {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  width: 100%;
  padding: 1em;
  box-shadow: 0px 0px 5px #d3d3d3;
}

.phoneNavbar img {
  opacity: .25;
}

.phoneNavbar ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
}

.phoneNavbar ul li {
  display: grid;
  place-items: center;
  font-size: .8em;
}

.phoneNavbar img {
  color: #ccc;
}
</style>
