import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css'
import './assets/styles/layout.scss'

import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'remixicon/fonts/remixicon.css'
import vClickOutside from 'click-outside-vue3'
import i18n from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'


import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import Notifications from '@kyvg/vue3-notification'

import mitt from 'mitt'

import { RestApi } from './service/RestApi'

const emitter = mitt()

const app = createApp(App).use(i18n).use(router)

app.config.globalProperties.$appState = reactive({ theme: 'saga-blue' })

app.provide('emitter', emitter)
app.provide(
  'restApi',
  new RestApi(
    process.env.VUE_APP_BACKEND_URL,
    jwtToken,
  ),
)

app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' })

app.use(Antd)
app.use(vClickOutside)

app.use(VueAxios, axios)

app.use(Notifications)

app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)

app.component('Button', Button)

app.mount('#app')
