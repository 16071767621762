<template>
  <div class="dropdown">
    <button @click="toggle" class="btn" :style="{backgroundColor: bgColor, color: color}"
            style="text-transform: uppercase;">
      {{ locale }}
      <span class="caret"></span>
    </button>
    <ul v-if="isOpen" class="items">
      <li v-for="(item, index) in activeLangs" :key="index">
        <a @click="changeLangCode(item.value)">{{ item.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {useRouter} from 'vue-router'

export default {
  props: {
    bgColor: {
      type: String,
      default: 'gray'
    },
    color: {
      type: String,
      default: 'white'
    }
  },

  setup() {
    const activeLangs = [
      {
        label: 'Türkçe',
        value: 'tr',
        flag: 'flag-icon-tr'
      },
      {
        label: 'English',
        value: 'en',
        flag: 'flag-icon-en'
      }
    ]

    const router = useRouter()



    const { locale } = useI18n({ useScope: 'global' })



    const isOpen = ref(false)

    const changeLangCode = (value) => {

      locale.value = value

      isOpen.value = false

      router.push({name: 'Index.Page'})

    }

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    return {

      isOpen,

      toggle,

      locale,
      changeLangCode,

      activeLangs,

    }

  }

}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.items {
  background-color: #fff;
  border: 1px solid #ccc;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  padding: 1em;
  list-style: none;
  display: grid;
  row-gap: .4em;
}

a {
  text-decoration: none;
}

.caret {
  margin-left: 5px
}

.caret:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
</style>
