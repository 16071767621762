<template>
  <div class="Layout1">
    <div class="item">
      <div v-if="showcaseItem.header.visible" class="widgetHeader">
        <div class="title">
          <span>
            {{ showcaseItem.header.title }}
          </span>
        </div>

        <div v-if="showcaseItem.header.right.visible" class="subtitle">
          <span :style="{ color: color.primary_color }">
            {{ showcaseItem.header.right.title }}
          </span>
        </div>
      </div>

      <div class="products">
        <div
          v-for="(item, index) in showcaseItem.body.items.slice(
            0,
            showcaseItem.body.item_count
          )"
          :key="index"
          class="product"
          :class="{ lowStock: item.total_inventory < 1 }"
        >
          <div class="image">
            <img :src="item.image_url" />
          </div>

          <div class="title">
            {{ item.title }}
          </div>

          <div class="price">
            <div class="del">
              <del v-if="item.discounted">{{ item.list_price }}</del>
            </div>
            <div
              class="current"
              :style="{ backgroundColor: color.secondary_color }"
            >
              {{ item.sell_price }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showcaseItem: {
      type: Object,
      required: true,
    },
    color: Object,
  },
};
</script>

<style scoped>
.Layout1 .products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em 0.6em;
}

.Layout1 .products .image img {
  border-radius: 10px;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.Layout1 .products .price {
  display: flex;
  justify-content: space-between;
}
</style>
