<template>
  <div class="layout-topbar">
    <router-link to="/">
      <img class="logo" src="../public/img/logo.svg" alt="Logo">
    </router-link>

    <div style="position: relative" class="notifications">
      <div style="position: absolute; left: 0; top: 0;">
        <notifications/>
      </div>
    </div>

    <button class="p-link layout-topbar-menu-button layout-topbar-button"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
      <i class="pi pi-ellipsis-v"></i>
    </button>


    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <ChangeLangDropdown :bgColor="'transparent'" :color="'black'"/>
      </li>
      <!--      <li>-->
      <!--        <button title="Destek" class="p-link layout-topbar-button">-->
      <!--          <i class="pi pi-question-circle"></i>-->
      <!--          <span>{{ t('support') }}</span>-->
      <!--        </button>-->
      <!--      </li>-->
      <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-user"></i>
          <span>{{ t('profile') }}</span>
        </button>
        <ul class="subTopMenu">
          <li>
            <a @click="logOut">
              <i class="pi pi-sign-out"></i>
              <span>{{ t('logOut') }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>

import { useI18n } from 'vue-i18n'
import ChangeLangDropdown from './components/ChangeLangDropdown'

export default {
  components: { ChangeLangDropdown },
  setup() {

    const { t, locale } = useI18n()

    return {

      t,
      locale,

    }

  },

  methods: {
    changeLanguage(value) {
      this.$root.$i18n.locale = value
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event)
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.theme.startsWith('saga')
    },
  },
}
</script>

<style scoped>
.layout-topbar-menu {
  position: relative;
  align-items: center;
}

.layout-topbar-menu li ul {
  display: none;
  background: #fff;
  box-shadow: 0 0 4px lightgrey;
  padding: 5px 8px;
}

.layout-topbar-menu li:hover ul {
  display: block;
}

.subTopMenu {
  position: absolute;
  padding-left: 0;
  list-style-type: none;
  width: max-content;
}

.logo {
  width: 125px;
}
</style>
