class GetColorSetsResponse {
  constructor(data) {
    this.data = data;
  }

  /**
   *
   *
   * @returns {GetColor[]}
   */
  getColors() {
    console.log("colors ", this.data.colors);
    return this.data.colors.map((color) => new GetColor(color));
  }
}

class GetColor {
  constructor(item) {
    this.item = item;
  }

  /**
   *
   * @return {string}
   */
  getName() {
    console.log("name ", this.item.name);
    return this.item.name;
  }

  /**
   *
   * @return {string}
   */
  getPrimaryColor() {
    return this.item.primary_color;
  }

  /**
   *
   * @return {string}
   */
  getSecondaryColor() {
    return this.item.secondary_color;
  }

  /**
   *
   * @return {boolean}
   */
  getSelected() {
    return this.item.selected;
  }
}

module.exports = GetColorSetsResponse;
