class GetNotificationsResponse {
  constructor(data) {
    this.data = data
  }

  /**
   * @return {GetNotificationItem[]}
   */
  getItems() {
    return this.data.items.map(item => new GetNotificationItem(item))
  }
}

class GetNotificationItem {
  constructor(item) {
    this.item = item
  }

  getEmail() {
    return this.item.email
  }

  getFirstName() {
    return this.item.first_name
  }

  getLastName() {
    return this.item.last_name
  }

  getStatus() {
    return this.item.status
  }

  getPlatform() {
    return this.item.platform
  }

  getTitle() {
    return this.item.title
  }

  getContent() {
    return this.item.content
  }

  getTargetUrl() {
    return this.item.target_url
  }

  getIconUrl() {
    return this.item.icon_url
  }

  getImageUrl() {
    return this.item.image_url
  }

  getSendingTime() {
    return this.item.send_time
  }

  getNotificationType() {
    return this.item.notification_type
  }


}

module.exports = GetNotificationsResponse
