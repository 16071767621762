<template>
  <div class="readOnly">
    <div class="Layout3">
      <div class="item">
        <div class="widgetHeader" v-if="showcaseItem.header.visible">
          <div class="title">
            <span v-if="showcaseItem.header.visible">
              {{ showcaseItem.header.title }}
            </span>
          </div>
          <div class="subtitle">
            <span
              v-if="showcaseItem.header.right.visible"
              :style="{ color: color.primary_color }"
            >
              {{ showcaseItem.header.right.title }}
            </span>
          </div>
        </div>
        <div class="widgetMain">
          <div
            class="products"
            :style="{
              gridTemplateColumns:
                'repeat(' + showcaseItem.body.items?.length + ', 1fr)',
            }"
          >
            <div
              class="product"
              :class="{
                small: showcaseItem.body.showing_item_count === 1.5,
                medium: showcaseItem.body.showing_item_count === 2.5,
                large: showcaseItem.body.showing_item_count === 3.5,
                xlarge: showcaseItem.body.showing_item_count === 4,
                lowStock: product.stock_count < 1,
              }"
              v-for="(product, index) in showcaseItem.body.items"
              :key="index"
            >
              <div class="image">
                <img :src="product.image_url" />
              </div>
              <div class="title">
                {{ product.title }}
              </div>
              <div class="price">
                <div class="del">
                  <del v-if="product.discounted">{{ product.list_price }}</del>
                </div>
                <div
                  class="current"
                  :style="{ backgroundColor: color.secondary_color }"
                >
                  {{ product.sell_price }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showcaseItem: {
      type: Object,
      required: true,
    },
    color: Object,
  },
};
</script>

<style scoped>
.Layout3 {
  overflow-x: scroll;
}

.Layout3 .products {
  display: grid;
  grid-template-rows: 1fr;
  gap: 1em;
  width: max-content;
}

.small {
  width: 220px;
}

.medium {
  width: 130px;
}

.large {
  width: 90px;
}

.xlarge {
  width: 75px;
}

.Layout3 .price {
  display: flex;
  justify-content: space-between;
}

.Layout3 img {
  border-radius: 5px;
  width: 100%;
  object-fit: contain;
}
</style>
